:root {
  --classyGreen: rgb(0, 138, 140);
  --hoverClassyGreen: rgb(2, 158, 161);
  --borderColor: rgb(171, 171, 171);
  --backgroundColor: rgb(247, 247, 250);
  --menuColor: rgb(42,60,78);
  --linkColor: #498fe5;
  --navbarColor: #0b0d0f;
  --navbarHoverColor: #212529;
  --navbarCatColor: rgb(166, 166, 166);
  --navbarSubCatColor: rgb(248, 248, 248);
}

body {
  font-size: 10px!important;
  min-height: 100ch;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

a {
  color: var(--linkColor);
  text-decoration: none;
}

.btn {
  padding: 0 2px;
  margin: 0!important;
  font-size: 10px;
  height: 22px;
  border-color: var(--borderColor);
  background-color: var(--classyGreen);
  color: white;

  &.contactModalButton {
    margin: 1px auto !important;
    width: 24px;
    height: 24px;
  }
}

.btn:hover {
  background-color: var(--hoverClassyGreen);
  border-color: var(--borderColor);
}

.btn:disabled {
  background-color: var(--hoverClassyGreen);
  border-color: var(--borderColor);
}

.btn-group {
  .dropdown {
    button > div {
      display: inline-block;
    }
  }

  a {
    width: 50px;
    button {
      width: 100%!important;
    }
  }

  .btn:not(.phoneNoteButtons) {
    width: 100px;
  }

  .form-check {
    label {
      margin-top: 11px;
    }
  }
}

.form-group {
  margin-bottom: 0;

  label {
    margin: 0;
  }
}

.row {
  margin: 0;
}

.col-md-1, .col-md-2 {
  margin: 0;
  padding: 0;
}

.col-md-3 {
  margin: 0;
  padding: 0;
}
.col-md-4 {
  margin: 0;
  padding: 0;
}
.col-md-5 {
  margin: 0;
  padding: 0;
}
.col-md-6 {
  margin: 0;
  padding: 2px;
}

.col-md-7 {
  margin: 0;
  padding: 0;
}

.col-md-8 {
  margin: 0;
  padding: 0;
}

.col-md-9 {
  margin: 0;
  padding: 0;
}

.col-md-10 {
  margin: 0;
  padding: 0;
}

.col-md-11 {
  margin: 0;
  padding: 0;
}

.col-md-12 {
  margin: 0;
  padding: 0;
}

.column-container {
  background-color: white;
  height: auto;
  width: auto;
  margin-right: 2px;
  margin-left: 2px;
  padding: 5px;
  border: 1px SOLID black;
}

.left-column {
  button {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
}

.right-column {
  .nav-tabs {
    margin-right: 2px!important;
    margin-left: 2px!important;
  }
}

.sidebar {
  margin: 0;
  padding: 0;
}

.content {
  margin: 0;
  padding: 0;
}

.nav-tabs {
  a {
    padding: 2px 4px;
    color: black;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
  }

  a:link {
    text-decoration: none;
  }
}

.nav-item {
  padding: 0 5px;
}

.tabs {
  background-color: var(--menuColor);
  height: 22px;
  margin-top: 2px;

  .nav-item {
    border: none;
    padding: 0 5px;
    border-radius: 0;
    color: white;
    height: 22px;
  }

  .nav-item.active {
    border: none;
    color: black;
  }
}

.table {
  margin-bottom: 1px;
  //background-color: #a9a9a9!important;

  .prio-none {
    color: inherit;
    font-weight: BOLD;
  }

  .prio-low {
    color: #008000;
    font-weight: BOLD;
  }

  .prio-medium {
    color: rgb(255, 150, 0);
    font-weight: BOLD;
  }

  .prio-high {
    color: rgb(228, 85, 75);
    font-weight: BOLD;
  }
}

table thead tr th {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 12px!important;
}

table tbody tr td {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 10px!important;
}

.card-body {
  padding: 5px 5px;
}

.form-check label {
  font-size: 10px;
  line-height: 0;
}

.navigation {
  width: 100%;
}

.side-menu {
  position: absolute!important;
  width: 150px!important;
  height: 100%!important;
  top: 0!important;
  left: 0!important;
  z-index: 1!important;
  font-weight: BOLD!important;
  background-color: var(--menuColor)!important;
  display: block;
  min-height: 100ch;

  .navbar-brand {
    margin: 0 auto;
  }

  .nav {
    height: 100%;
  }

  .nav-item {
    padding: 2px 5px;
  }

  .nav-item:hover {
    background-color: var(--navbarHoverColor);
  }
  .nav-link {
    padding: 5px 10px;
  }

  h5.menu-category {
    font-size: 14px!important;
    margin-bottom: 0;
    padding-left: 5px;
    color: var(--navbarCatColor);
  }

  a.menu-subcategory {
    color: var(--navbarSubCatColor);
    //color: var(--navbarCatColor);
    text-decoration: none;

    .nav-item {
      font-size: 12px!important;
      padding-left: 15px;
    }
  }
}

.tab-page {
  left: 150px;
}

.content-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 0;
  padding-left: 150px;
  overflow: hidden;
  background-color: var(--backgroundColor);
}

.ticketTime {
  position: absolute;
  right: 0;
  box-shadow: none;
  margin-top: 5px;
  margin-right: 210px;
  color: white;

  font-weight: BOLD;

  &.ticketEdit {
    margin-right: 310px;
  }
}

.submitButton {
  position: absolute;
  right: 0;
  box-shadow: none;
  width: 200px;

  &.ticketEdit {
    width: 300px;
  }

  .btn {
    height: 22px;
    width: 100px;
    border-bottom: 1px solid #dee2e6;
  }

  .removeButton {
    //color: black;
    width: 100px!important;
    background-color: #F73939;
  }

  .removeButton:hover {
    //opacity: 0.8;
    background-color: #FA6666;
  }
}

.removeButtonSmall {
  background-color: inherit;
  padding: 0;
  margin: 0 auto;
  text-align: CENTER;
  font-weight: BOLD;
}

.ticketSettings .form-group {
  margin: 2px 0!important;
}

.form-control-sm {
  height: 24px;
  padding: 0 5px;
}

.ticketForm .form-group {
  margin: 2px 0!important;
}

.form-control-sm {
  height: 24px;
  padding: 0 5px;
}

.form-group .form-label {
  font-size: 10px;
  padding: 2px 2px;
}

.form-group .col-sm-4 {
  padding: 0;
}

.ticketDetailsColumn {
  .css-1wy0on6 {
    z-index: 0;
  }
}

.css-1wy0on6 {
  height: 22px!important;
  z-index: 1;

  background-color: inherit;

  .css-16pqwjk-indicatorContainer {
    background-color: inherit;
  }
}

.scrollableTable {
  max-height: calc(100vh - 550px);
  overflow-y: auto;

  .css-kj6f9i-menu {
    position: relative;
    z-index: 200;
    margin-top: 1px;
    overflow: auto;
  }
}

.ticketActionTable tbody.field-array-row tr td {
  padding: 0;
}

.ticketActionTable tbody.field-array-row tr td button {
  min-width: 22px!important;
  min-height: 22px!important;
  padding: 0;
}

.ticketActionTable tbody.field-array-row .row button {
  padding: 1px 2px;
}

.ticketHardwareTable tbody.field-array-row tr td {
  padding: 0;
}

.ticketHardwareTable tbody.field-array-row tr td button {
  min-width: 22px!important;
  min-height: 22px!important;
  padding: 0;
}

.ticketHardwareTable tbody.field-array-row .row button {
  padding: 1px 2px;
}

.procedures{
  margin-top: 10px;
}

.procedures button {
  width: 100%;
  height: 100px;
}

.klantDetails {
  margin-top: 10px;
}

.table {
  margin-top: 10px;
  margin-bottom: 1px;

  .form-check-input {
    margin-top: 0;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.table thead tr th {
  padding: 2px 2px;
  margin: 0;
  line-height: 1;
  font-size: 12px;
}

.table tbody tr td {
  padding: 2px 2px;
  margin: 0;
  line-height: 1;
  font-size: 10px;
}

.ticketOverzicht {
  margin-top: 0!important;
}

.ticketOverzicht label {
  color: var(--mainColor);
}

.userOverzicht {
  margin-top: 0!important;

  label{
    color: #007bff;
    margin-bottom: 0;
  }
}

.relations {
  margin-top: 10px;
}

.relationData {
  margin-top: 10px;
}

.contactData {
  margin-top: 10px;
}

.pageContainer {
  margin-top: 10px;
}

.auditContainer {
  margin-top: 0;
  padding-top: 0;

  .css-1pcexqc-container {
    padding-top: 0!important;
  }


  .auditList {
    //position: absolute;
    max-height: calc(100vh - 235px);
    overflow-y: auto;
    li {
      margin-bottom: 2px;
    }
  }

  ul {
    list-style-type: none;
    margin-bottom: 0;
  }

  .chatItem {
    width: 100%;
    border: 1px solid rgb(217, 217, 217);
    padding: 5px;
    margin: 0;
    background-color: #fafafa;
    div {
      width: auto;
    }
  }

  .header {
    font-weight: bold;
  }

  .chatItem::after {
    content: "";
    clear: both;
    display: table;
  }

  //.chatItem img {
  //  float: left;
  //  max-width: 60px;
  //  width: 100%;
  //  margin-right: 20px;
  //  border-radius: 50%;
  //}

  .chatItem .engineerName {
    float: left;
  }

  .chatItem .dateTime {
    font-size: 10px;
    float: right;
  }

  .chatItem .message {
    display: block;
    position: relative;
    width: 100%;
    margin-right: 10px;
    //margin-left: 10px;
    margin-top: 15px;
  }

  .chatItem .message p {
    font-size: 10px;
    //line-height: 0;
    margin-bottom: 5px;
    //margin-right: 25px;
  }

  .chatItem {
    button {
      height: auto!important;
      width: auto!important;
      margin: 0!important;
      margin-top: 1px!important;
      svg {
        vertical-align: top!important;
        margin-top: 2px!important;
      }
    }
  }

  ul {
    padding: 0;
  }

  .messageInputs {
    margin: 0!important;
    //padding: 0!important;

    div {
      margin: 0;
      //padding: 0;

      input {
        margin: 0!important;
        font-size: 10px!important;
      }
    }
  }

  .form-group {
    margin-bottom: 2px;

    textarea {
      font-size: 10px;
      padding: 1px 4px;
      margin: 0!important;
    }
  }

  .dateTime {
    font-weight: BOLD;
  }
}

.integrationTable {
  label{
    color: #007bff;
    margin-bottom: 0;
  }
  .dropdown-menu {
    padding: 0 2px;
    margin: 0;

    a {
      padding: 2px 5px;
      margin: 0;
      font-size: 12px;
    }
  }
}

.newIntegrationPage {
  .card {
    padding: 5px;
    border: 1px SOLID black;
  }

  img {
    min-height: 269px;
    min-width: 269px;
  }

  .card-body {
    font-size: 10px;
  }
}

.ticketTable {
  .dropdown-menu {
    padding: 0 2px;
    margin: 0;

    a {
      padding: 2px 5px;
      margin: 0;
      font-size: 12px;
    }
  }
}

.btn-collapse {
  background-color: inherit;
  border: none;
  color: var(--classyGreen);
  font-weight: BOLD;
}

.btn-collapse:hover {
  background-color: inherit;
  color: var(--hoverClassyGreen);
}

.taskContent {
  max-height: calc(100vh - 545px);
  overflow-y: auto;

  #taskContent {
    padding-left: 2px;
    padding-right: 2px;
  }

  .col {
    max-width: 25px!important;
  }

  .col-sm-5 {
    min-width: 45%!important;
    margin: 0!important;
    padding: 0!important;
  }

  .css-bg1rzq-control {
    border: none;
  }

  .css-bgvzuu-indicatorSeparator {
    display: none
  }

  .css-kj6f9i-menu {
    position: inherit;
    margin-top: 1px;
    overflow: auto;
  }

  .task-input {
    height: 24px!important;
    border: none;
    margin: 0!important;
    padding: 0!important;
  }

  .task-input:focus {
    box-shadow: none;
  }

  .removeButtonX {
    font-size: 10px;
    font-weight: BOLD;
    color: RED;
    margin-bottom: 0;
    padding-top: 5px;
    text-align: right;
    cursor: pointer;
  }
}

.table {
  thead {
    th {
      input {
        height: 22px;
        font-weight: BOLD;
        background-color: inherit;
        border: none;
        text-decoration: underline;
      }
    }
  }
}

.tickets-overview-container-fluid {
  border: 1px SOLID var(--borderColor);
  max-height: calc(100vh - 208px);
  overflow-y: auto;
  width: 100%;

  .table {
    margin: 0;

    thead {
      .filter-label {
        margin-bottom: 0;
        margin-top: 5px;
        display: block;
      }
    }
  }
}

.phonenotes-overview-container-fluid {
  border: 1px SOLID var(--borderColor);
  max-height: calc(100vh - 208px);
  overflow-y: auto;
  width: 100%;

  .table {
    margin: 0;

    thead {
      .filter-label {
        margin-bottom: 0;
        margin-top: 5px;
        display: block;
      }
    }
  }
}

.actionDescription {
  min-height: 24px!important;
}

.ck-editor {
  .ck-editor__main {
    max-height: 250px;
    overflow-y: auto;

    p {
      margin: 0;
    }
  }
}

.ticketHistory {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

hr {
  background-color: var(--menuColor);
}

.navbarLogo {
  width: 50px;
  height: 50px;
  margin: 0 45px;
  position: relative;
}

.ticketTable {
  table{
    background-color: white;
  }
}

.backgroundLogo {
  width: 532px;
  height: 350px;
  top: calc(100vh - 430px);
  position: absolute;
  left: calc(100vw - 682px);
  overflow: hidden;
  right: auto;
  z-index: -100;
}

.css-kj6f9i-menu {
  margin-top: 1px!important;
}

.bold-table-row {
  font-weight: BOLD;
}

.ticket-icon {
  transform: rotate(45deg);
}

.ticketOverviewDropdown {
  border-radius: 0;
  background-color: var(--menuColor);

  .dropdown-item {
    color: var(--navbarSubCatColor);
  }

  .dropdown-item:hover {
    background-color: var(--navbarHoverColor);
  }
}