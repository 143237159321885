@import "variables";

table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.navbar-brand {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.btn, .form-control {
  border-radius: 0;
  margin: 2px 0!important;
}
.form-label {
  font-size: 10px;
}

.form-row-input {
  margin-bottom: 0;
}

//.nav-link {
//  font-size: 14px;
//  padding: 0 0 0 10px;
//}

//.side-menu {
//  height: 100vh - 8.25;
//}

.col {
  padding: 0;
}

.card {
  border: none;
}

hr {
  margin-top: 8px;
  margin-bottom: 5px;
}

input, textarea, thead tr th {
  font-size: 10px!important;
}

.css-1pcexqc-container {
  padding: 1px!important;
}

.css-bg1rzq-control, .css-bg1rzq-control:focus {
  border-radius: 0!important;
  font-size: 10px;
}

.css-1szy77t-control, .css-1sszy77t-control:focus {
  border-radius: 0!important;
  font-size: 10px;
}

.css-kj6f9i-menu {
  border-radius: 0!important;
  font-size: 10px!important;
}

.css-fk865s-option {
  padding: 0;
}

.select-container div {
  //height: 24px;
}

.multi-select {

  span {
    margin: 0;
    padding: 0;
  }

  .css-bg1rzq-control {
    .css-1hwfws3 {
      padding: 0!important;
      height: auto!important;
      position: static!important;
    }
  }

  .css-1hwfws3 {
    padding: 0!important;
    height: auto!important;
    position: static!important;
  }
}

.select-container {
  .css-1szy77t-control {
    min-height: 0;
    height: 24px;

    .css-1hwfws3 {
      height: 24px;
      position: static;
    }

    .css-1hwfws3 .css-1g6gooi {
      padding: 0;
      height: 24px;
    }

    .css-1wy0on6 {
      height: 24px;

      .css-1thkkgx-indicatorSeparator {
        display: none;
      }
      .css-1thkkgx-indicatorContainer {
        padding: 0;
      }
    }

    .css-kj6f9i-menu {
      .css-11unzgr > div {
        width: 200px!important;
        padding: 0!important;
      }
    }

  }

  .css-bg1rzq-control,
  .css-bg1rzq-control:focus {
    min-height: 0;
    //height: 24px;

    .css-1hwfws3 {
      height: 24px;
      position: static;
    }

    .css-1wy0on6 {
      height: 24px;

      .css-bgvzuu-indicatorSeparator {
        //display: none;
      }
      .css-16pqwjk-indicatorContainer {
        padding: 0;
      }
    }
  }
}

.hideInputs {
  display: none;
}