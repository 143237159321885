$sidebarWidth: 150px;
$tabBarHeight: 50px;

$tabItemWidth: 175px;
$tabbarColor: #343a40 !important;
$tesr: rgb(42,60,78) !important;

.nav-item {
  font-size: 14px!important;
}


.custom-nav-tabs {
  background: $tesr;
  width: calc(100% - 150px );
  position: absolute;
  left: $sidebarWidth;
  height: $tabBarHeight;
  border: none;
  overflow: hidden;

  a:link {
    color: inherit;
  }

  .nav-item {
    position: relative;
    width: $tabItemWidth;
    height: $tabBarHeight;
    background: #a4a5a8;
    padding: 0;
    margin-bottom: 0!important;
    //border-left: 1px solid #818285;

    .home-tab-icon {
      font-size: 28px;
      margin-top: 10px;
      margin-left: 5px;
    }

    .close-tab-icon {
      position: absolute;
      top: 5px;
      right: 6px;
    }

    .close-tab-icon:hover {
      position: absolute;
      top: 5px;
      right: 6px;
      color: orange;
      cursor: pointer;
    }

    .tab-title {
      position: absolute;
      top: 2px;
      left: 30px;
    }

    .tab-icon {
      position: absolute;
      top: 18px;
      left: 5px;
      font-size: 20px;
    }

    img {
      //.tab-icon {
        position: absolute;
        top: 18px;
        left: 5px;
        width: 20px;
        height: 20px;
      //}
    }

    .nav-link {
      height: $tabBarHeight;
      border: none;
      border-radius: 0!important;
      font-weight: 600;
    }

    .nav-link.active {
      background: #eee;
    }
  }

  .home-tab {
    width: 50px;
    border: none;
  }

}

.custom-content-tabs {
  position: absolute;
  left: $sidebarWidth;
  top: $tabBarHeight;
  right: $sidebarWidth;
  width: calc(100% - 150px);
  height: calc(100vh - 55px);
}
